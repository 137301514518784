import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

export default class Storage {
  static getJwt = () => {
    if (navigator.cookieEnabled) {
      const cookies = new Cookies();
      return cookies.get("jwt");
    }

    return localStorage.getItem("jwt");
  };
  static setJwt = (data: string | null) => {
    if (navigator.cookieEnabled) {
      const cookies = new Cookies();
      if (!data) {
        return cookies.remove("jwt", { path: "/" });
      }
      return cookies.set("jwt", data, {
        path: "/",
        secure: true,
        maxAge: 31536000,
      });
    }

    return localStorage.setItem("jwt", data || "");
  };
  static isValidJwt = (): boolean => {
    const jwt = Storage.getJwt();
    try {
      if (!jwt) return false;

      const { exp }: { exp: number } = jwt_decode(jwt);

      return exp > Date.now() / 1000;
    } catch (error) {
      return false;
    }
  };
  static getRedirectSource = () => {
    return localStorage.getItem("redirectSource");
  };
  static setRedirectSource = (data: string | null) => {
    if (!data) return;
    return localStorage.setItem("redirectSource", data);
  };
  static getUserId = () => {
    return localStorage.getItem("userId");
  };
  static setUserId = (data: string) => {
    return localStorage.setItem("userId", data);
  };
  static getEmail = () => {
    return localStorage.getItem("email");
  };
  static setEmail = (data: string) => {
    return localStorage.setItem("email", data);
  };
  static isAdmin = () => {
    return localStorage.getItem("admin") === "true";
  };
  static setAdmin = (data: boolean) => {
    return localStorage.setItem("admin", data.toString());
  };
  static isTrainer = () => {
    return localStorage.getItem("trainer") === "true";
  };
  static setTrainer = (data: boolean) => {
    return localStorage.setItem("trainer", data.toString());
  };
  static getFullName = () => {
    return `${localStorage.getItem("firstName")} ${localStorage.getItem(
      "lastName"
    )}`;
  };
  static getFirstName = () => {
    return localStorage.getItem("firstName");
  };
  static setFirstName = (data: string) => {
    return localStorage.setItem("firstName", data);
  };
  static getLastName = () => {
    return localStorage.getItem("lastName");
  };
  static setLastName = (data: string) => {
    return localStorage.setItem("lastName", data);
  };
}
