// import Network from "./Network";
import Storage from "../Libs/Storage";
import { Network } from "./Network";

export async function Login(email: string, password: string) {
  const response = await Network.post("/authentication/email", false, {
    email,
    password,
    platform: "web",
  });

  if (response.status !== 200) {
    // Process form error and show user
    console.log("Fail network request:", response.errors);
    return response;
  }

  Storage.setJwt(response.data.token);

  return response;
}

export async function Logout(useRedirect = false) {
  Storage.setJwt(null);
  localStorage.clear();

  const useToken = new URLSearchParams(window.location.search).get("token");

  let url = "/login";
  const params = [];
  if (useRedirect && window.location.pathname !== "/") {
    params.push(`redirect=${window.location.pathname}`);
  }
  if (useToken) {
    params.push(`token=${useToken}`);
  }

  if (params.length > 0) {
    url += "?" + params.join("&");
  }

  window.location.href = url;
  return true;
}
