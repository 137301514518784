import { MicrophoneIcon, StopIcon, ArrowPathIcon } from "@heroicons/react/24/solid";
import { ReactElement, useEffect, useState } from "react";
import { useAudioRecorder } from 'react-audio-voice-recorder';

const Transcribe = (props: { onComplete: (arg0: string) => void }): ReactElement => {
  const [playbackUrl, setPlaybackUrl] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
    recordingTime,
  } = useAudioRecorder();

  const ButtonIcon = () => {
    if (isRecording) {
      return <StopIcon className="w-8 h-8 fill-white" />;
    } else if (loading) {
      return <ArrowPathIcon className="w-8 h-8 fill-white animate-spin" />;
    }

    return <MicrophoneIcon className="w-8 h-8 fill-white" />;
  }

  useEffect(() => {
    if (recordingBlob) {
      setPlaybackUrl(URL.createObjectURL(recordingBlob));

      setLoading(true);

      // Upload to process transcription
      props.onComplete("Test repsonse");

      setLoading(false);
    }
  }, [recordingBlob]);
  
  return (
    <div>
      <span className="text-white">Press & Hold</span>
      <div className="flex items-center justify-center w-16 h-16 rounded-full cursor-pointer bg-sky-500" onPointerDown={startRecording} onPointerUp={stopRecording}>
        <ButtonIcon />
      </div>
      { playbackUrl && <audio src={playbackUrl} controls /> }
    </div>
    );
};

export default Transcribe;