
import { useEffect } from "react";

import { Logout } from "../Libs/Authentication";

export default function LogoutScreen() {
  useEffect(() => {
    Logout();
  }, []);


  return (
    <></>
  )
}
