import { ReactElement } from "react";
import Knob from "./Knob";
import SevenSegDigit from "./SevenSegDigit";
import DoubleArrow from "../../Assets/Images/double-arrow.svg"

const SimulatorPanel = (): ReactElement => {
  return <div>
    <div className="relative h-fit bg-black rounded-xl w-[600px] m-4 p-4 space-y-4">
      <div className="">
        <span className="font-bold text-white">Radio Simulator</span>
      </div>
      <div className="flex items-center justify-center space-x-10">
        <SevenSegDigit frequency="118.5" />
        <SevenSegDigit frequency="127.5" />
        <Knob radius={40} />
        <div className="flex flex-col px-2 text-center bg-white rounded-lg h-fit">
          <img className="h-16" src={DoubleArrow} />
        </div>
      </div>
    </div>
  </div>;
};

export default SimulatorPanel;