
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import logo from "../Assets/Images/logo-512.png";
import Storage from "../Libs/Storage";
import { Login } from "../Libs/Authentication";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DEFAULT_REDIRECT_URL = "/";

export default function LoginScreen() {
  const query = useQuery();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState<string | null>(DEFAULT_REDIRECT_URL);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState();

  useEffect(() => {
    if (Storage.isValidJwt()) {
      navigate(DEFAULT_REDIRECT_URL);
    }
  }, []);

  useEffect(() => {
    const newRedirect = query.get("redirect");
    Storage.setRedirectSource(query.get("redirectSource"));
    if (newRedirect !== "/" && newRedirect !== "/login")
      setRedirect(newRedirect);

    const token = query.get("token");
    if (!token) return;

    Storage.setJwt(token);
    query.delete("redirect");
    query.delete("redirectSource");
    query.delete("token");

    navigate(
      newRedirect &&
        window.location.pathname !== newRedirect &&
        window.location.pathname !== "/" &&
        newRedirect !== "/login"
        ? newRedirect
        : DEFAULT_REDIRECT_URL
    );
  }, [query]);

  const performLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    setLoading(true);
    setErrors(undefined);

    try {
      const response = await Login(email, password);
      setLoading(false);

      if (response.errors && response.errors.length > 0) {
        setLoading(false);
        setErrors(response.errors);
        return;
      }

      navigate(redirect || DEFAULT_REDIRECT_URL);
    } catch (error) {
      console.log("Error: ", error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="w-auto h-24 mx-auto"
            src={logo}
            alt="WingSpeak"
          />
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900">Login to WingSpeak</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={performLogin}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={e => setEmail(e.target.value.toLowerCase())}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={e => setPassword(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loading || !email || !password}
                  className="flex justify-center w-full px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-sky-600 hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
