import { useState } from "react";

const Knob = ({value = 0, radius = 100}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [deg, setDeg] = useState(value);

  const onMouseDown = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setIsDragging(true);
  };
  
  const onMouseUp = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const onMouseMove = (e: { clientX: any; clientY: any; }) => {
    if (isDragging) {
      const mPos = {x: e.clientX, y: e.clientY};
      
      const atan = Math.atan2(mPos.x-radius, mPos.y-radius);
      let newDeg = -atan/(Math.PI/180) + 180;
      
      if(newDeg === 360) newDeg = 0;

      setDeg(newDeg);
    }
  };

  const handleChange = (e: { target: { value: any; }; }) => {
    const newDeg = e.target.value;
    setDeg(newDeg);
  };

  return (
    <div>
      <div className="relative bg-gray-500 rounded-full "
        style={{
          width: `${2*radius}px`,
          height: `${2*radius}px`
        }}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
      >
        <span
          style={{
            transform: `rotate(${deg}deg)`,
            height: `${2*radius}px`
          }}
          className={`absolute rotate-90 -ml-[1.5px] w-[3px] left-1/2 origin-center bg-transparent h-[${2*radius}px] after:absolute after:top-0 after:left-0 after:z-10 after:w-full after:h-1/4 after:bg-white`} />
      </div>
      {/* <input
        onChange={handleChange}
        value={Math.round(deg)}
        min="0"
        max="360"
        type="number"
      /> */}
    </div>
  );
};

export default Knob;