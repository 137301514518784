import Segment0 from '../../Assets/Images/LCD/7Segment0.min.svg';
import Segment1 from '../../Assets/Images/LCD/7Segment1.min.svg';
import Segment2 from '../../Assets/Images/LCD/7Segment2.min.svg';
import Segment3 from '../../Assets/Images/LCD/7Segment3.min.svg';
import Segment4 from '../../Assets/Images/LCD/7Segment4.min.svg';
import Segment5 from '../../Assets/Images/LCD/7Segment5.min.svg';
import Segment6 from '../../Assets/Images/LCD/7Segment6.min.svg';
import Segment7 from '../../Assets/Images/LCD/7Segment7.min.svg';
import Segment8 from '../../Assets/Images/LCD/7Segment8.min.svg';
import Segment9 from '../../Assets/Images/LCD/7Segment9.min.svg';

const SevenSegDigit = ({ frequency }: { frequency: string }) => {

  const segments = frequency.split('').map((digit) => {
    let value;
    switch (digit) {
      case '0':
        value = Segment0;
        break;
      case '1':
        value = Segment1;
        break;
      case '2':
        value = Segment2;
        break;
      case '3':
        value = Segment3;
        break;
      case '4':
        value = Segment4;
        break;
      case '5':
        value = Segment5;
        break;
      case '6':
        value = Segment6;
        break;
      case '7':
        value = Segment7;
        break;
      case '8':
        value = Segment8;
        break;
      case '9':
        value = Segment9;
        break;
      default:
        return <div className="text-2xl text-red-500">.</div>;
    }
    return <img className="w-10" src={value} />;
  });

  return (
    <div className="flex" >
      {segments.map((segment) => segment)}
    </div>
  );
};

export default SevenSegDigit;