import { ReactElement } from "react";
import logo from "../Assets/Images/logo-512.png";

const HomeScreen = (): ReactElement => {
  return <div className="flex flex-col items-center justify-center w-full h-screen bg-gray-700">
    <div className="w-1/4 h-fit">
      <img  src={logo} />
    </div>
    <div className="mt-4 text-6xl font-semibold text-white">WingSpeak</div>
  </div>;
};

export default HomeScreen;