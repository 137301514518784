import axios, { Method } from "axios";

import Storage from "./Storage";
import { Logout } from "./Authentication";

export interface Response {
  status: number;
  data: any;
  meta: any;
  errors: any;
}

interface RequestProps {
  method: Method;
  route: string;
  useJwt: boolean;
  data: any;
}

export interface IError {
  tag: string;
  description: string;
}

export class Network {
  static get = (
    route: string,
    useJwt = false,
    params?: any
  ): Promise<Response> => {
    return Network.performRequest("get", route, useJwt, params);
  };

  static post = (
    route: string,
    useJwt: boolean,
    params?: any
  ): Promise<Response> => Network.performRequest("post", route, useJwt, params);

  static put = async (
    route: string,
    useJwt: boolean,
    params?: any
  ): Promise<Response> => Network.performRequest("put", route, useJwt, params);

  static patch = (
    route: string,
    useJwt: boolean,
    params?: any
  ): Promise<Response> =>
    Network.performRequest("patch", route, useJwt, params);

  static delete = async (
    route: string,
    useJwt: boolean,
    params?: any
  ): Promise<Response> =>
    Network.performRequest("delete", route, useJwt, params);

  static performRequest = async (
    method: Method,
    route: string,
    useJwt = false,
    data = null
  ): Promise<any> => {
    let headers = {};

    const jwt = await Storage.getJwt();
    if (useJwt && jwt) {
      headers = {
        Authorization: `Bearer ${jwt}`,
      };
    }

    try {
      route = route.charAt(0) === "/" ? route.substr(1) : route;

      console.log(
        "NETWORK REQUEST w/ data",
        method,
        `${process.env.REACT_APP_API_URL}/${route}`,
        data,
        headers
      );

      const response = await axios({
        method,
        url: `${process.env.REACT_APP_API_URL}/${route}`,
        params: method === "get" ? data : null,
        data: method !== "get" ? data : null,
        headers,
      });

      console.log("Network Response: ", response.status, response.data);

      return {
        status: response.status,
        data: response.data.data || [],
        meta: response.data.meta || [],
      };
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        console.log("Not axios error?");
        return console.error(error);
      }
      console.log(
        "Error in network catch:",
        method,
        route,
        error.response?.status,
        error.response?.data
      );

      if (error.response?.status === 404) {
        return {
          errors: [
            {
              tag: "not_found",
              message: "Resource was not found",
            },
          ],
          status: 404,
        };
      }

      if (
        error.response?.status === 401 &&
        (error.response?.data.error === "Unauthorized" ||
          (error.response.data.errors &&
            error.response.data.errors.find(
              (e: IError) => e.tag === "authentication_error"
            ))) &&
        route !== "authentication/email"
      ) {
        try {
          await Logout(true);
        } catch (error) {
          console.error("JWT refresh failed: ", error);
          await Logout(true);
        }
      }

      if (
        !error.response ||
        !error.response.data ||
        !error.response.data.errors
      ) {
        if (error.response?.data.error) {
          return {
            errors: [
              {
                tag: error.response?.data.error,
                message: error.response?.data.message,
              },
            ],
            status: error.response?.data.status,
          };
        }

        return {
          errors: [
            {
              tag: "unknown_error",
              message: "An unknown error has occured",
            },
          ],
          status: 500,
        };
      }

      return {
        status: error.response.status,
        errors: error.response.data.errors,
      };
    }
  };

  static downloadFile = async (
    method: Method,
    route: string,
    useJwt = false,
    data = null
  ): Promise<any> => {
    let headers = {};

    const jwt = await Storage.getJwt();
    if (useJwt && jwt) {
      headers = {
        Authorization: `Bearer ${jwt}`,
      };
    }

    try {
      route = route.charAt(0) === "/" ? route.substr(1) : route;

      console.log(
        "NETWORK REQUEST w/ data",
        method,
        `${process.env.REACT_APP_API_URL}/${route}`,
        data,
        headers
      );

      const response = await axios({
        method,
        url: `${process.env.REACT_APP_API_URL}/${route}`,
        params: method === "get" ? data : null,
        data: method !== "get" ? data : null,
        headers,
        responseType: "blob",
      });

      console.log("Network Response: ", response.status, response.data);

      return response;
    } catch (error) {
      console.log("Error in network catch:", method, route, error);
    }
  };
}

export class S3 {
  static performFileUpload = async (
    signedUrl: string,
    file: File
  ): Promise<boolean> => {
    try {
      const response = await axios.put(signedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      if (response.status !== 200) {
        console.error("Upload of file to S3 failed.");
        return false;
      }

      return true;
    } catch (error) {
      console.error("Error on upload media: ", error);
    }

    return false;
  };
}
