import { useState, useMemo } from 'react'
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { Network, Response } from "../Libs/Network";
import { PaperAirplaneIcon, MicrophoneIcon } from '@heroicons/react/24/outline';
import Transcribe from '../Components/Transcribe';
import SimulatorPanel from '../Components/SimulatorPanel';
import { PencilSquareIcon } from '@heroicons/react/24/solid';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const scrollToBottom = (id: string) => {
  const element = document.getElementById(id);

  if (!element) return;

  element.scrollTop = element.scrollHeight;
}

export const NBConversationLoader = async (request: any) => {
  if (request.params.conversationId) {
    return Network.get(`/conversations/${request.params.conversationId}`, true);
  }

  return { data: {}, errors: {} };
}
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Conversation() {
  const navigate = useNavigate();
  let query = useQuery();

  const [conversation, setConversation] = useState<any>(useLoaderData() as Response)
  const [question, setQuestion] = useState<string>()
  const [pendingQuestion, setPendingQuestion] = useState<string>()
  const [type, setType] = useState<string>("support")
  const [inputMethod, setInputMethod] = useState<"keyboard" | "microphone">("keyboard")
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState()

  // const { data, errors } = useLoaderData() as Response;

  const onSubmit = async (e?: {
    preventDefault: () => void;
  }): Promise<void> => {
    e && e.preventDefault();

    setErrors(undefined);

    setLoading(true);

    setPendingQuestion(question);
    setQuestion("");

    try {
      const response = await Network.post(conversation?.data?.id ? `/conversations/${conversation.data.id}` : "/conversations", true, {
        type,
        question,
      });

      if (response.errors) {
        throw errors;
      }
      setConversation({
        data: response.meta,
      });
      scrollToBottom("scroll");

      navigate(`/nb/conversations/${response.meta.id}`);
    } catch (error) {
      setQuestion(question)
      console.log("Error:", error);
      setErrors(errors);
      alert("Something went wrong");
    }

    setLoading(false);
    setPendingQuestion("");
  };

  const onEnterPress = (e: { keyCode: number; shiftKey: boolean; preventDefault: () => void; }) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      onSubmit();
    }
  }

  return (
    <div className="flex flex-col h-screen max-w-3xl mx-auto">
      {/* <div className="flex flex-col items-center w-full my-4 space-y-3">

        {type === "support" ?
          <div className="text-base font-semibold text-gray-300 ">Support AMA with piggy!</div>
          :
          <div className="text-base font-semibold text-gray-300">Search for the truffle of numbers with piggy!</div>
        }
        <span className="inline-flex rounded-md shadow-sm isolate">
          <Link
            to="/conversations?type=support"
            className={classNames(type === "support" ? "bg-gray-500" : "bg-gray-800", "relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-200  rounded-l-md ring-1 ring-inset ring-gray-600 hover:bg-gray-900 focus:z-10")}
          >
            <LifebuoyIcon className="w-5 h-5 mr-2" /> Support
          </Link>
          <Link
            to="/conversations?type=support" //"/nb/conversations?type=numberSearch"
            className={classNames(type === "numberSearch" ? "bg-gray-500" : "bg-gray-800", " cursor-not-allowed relative inline-flex items-center px-3 py-2 -ml-px text-sm font-semibold text-gray-200  rounded-r-md ring-1 ring-inset ring-gray-600 hover:bg-gray-900 focus:z-10")}
          >
            <MagnifyingGlassIcon className="w-5 h-5 mr-2" /> Search Inventory
          </Link>
        </span>
      </div> */}

      <SimulatorPanel />

      <div id="scroll" className="flex flex-col flex-grow px-3 overflow-y-auto text-sm">
        <div className="flex flex-1">
          {(!conversation?.data?.messages.length && !pendingQuestion) && (
            <div className="self-center w-full italic text-center text-gray-300 ">Get Started! Try asking: What are the four forces of flight?</div>
          )}
        </div>

        {conversation?.data?.messages.map((message: any, index: number) => {
          if (message.author === "system") {
            return null;
          }

          if (message.author === "assistant") {
            return <div key={index} title={message.createdAt.toString()} className="max-w-sm p-3 m-1 whitespace-pre-line border rounded-md bg-sky-300 border-sky-200 md:max-w-lg w-fit h-fit">{message.content} <p className="mt-2 text-xs italic">Source: {message.source || "N/A"}</p></div>;
          }

          return <div key={index} title={message.createdAt.toString()} className="self-end max-w-sm p-3 m-2 text-white whitespace-pre-line bg-gray-600 border border-gray-500 rounded-md md:max-w-lg w-fit h-fit">{message.content}</div>
        })}

        {pendingQuestion &&
          <>
            <div title="Pending" className="self-end max-w-lg p-3 m-2 text-white bg-gray-600 border border-gray-500 rounded-md w-fit h-fit">{pendingQuestion}</div>
            <div title={"Thinking"} className="max-w-lg p-3 m-1 border rounded-md bg-sky-300 border-sky-200 w-72 h-fit">
              <div className="flex-1 py-1 space-y-6 animate-pulse">
                <div className="h-2 bg-gray-200 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 col-span-2 bg-gray-200 rounded"></div>
                    <div className="h-2 col-span-1 bg-gray-200 rounded"></div>
                  </div>
                  <div className="h-2 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>
          </>
        }
      </div>

      {errors && <div className="text-red-600">Something went wrong, check console log</div>}

      {inputMethod === "microphone" ? (
        <div className="flex items-center justify-center w-full space-x-4">
          <Transcribe onComplete={(text) => console.log("Transcription completed with:", text)} />
          <div onClick={() => setInputMethod("keyboard")} className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-sky-500">
            <PencilSquareIcon className="w-4 h-4 fill-white" />
          </div>
        </div>
      ) : (
        <div className="flex-none w-full border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient bg-white dark:bg-gray-800 md:!bg-transparent dark:md:bg-vert-dark-gradient pt-2">
          <form onSubmit={onSubmit} className="flex flex-row gap-3 stretch last:mb-2 md:mx-2 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl">
            <div className="relative flex items-stretch flex-1 h-full md:flex-col">
              <div className="">
                <div className="flex justify-center h-full gap-0 ml-1 md:w-full md:m-auto md:mb-2 md:gap-2">
                </div>
              </div>
              <div className="flex flex-col w-full py-2 flex-grow md:py-3 md:pl-4 relative border border-black/10 bg-white dark:border-gray-900/50 dark:text-white dark:bg-gray-600 rounded-md shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)]">
                <textarea
                  id="prompt-textarea"
                  disabled={loading}
                  onKeyDown={onEnterPress}
                  value={question}
                  onChange={(e): void => {
                    setQuestion(e.target.value);
                  }} tabIndex={0} data-id="root" rows={1} placeholder="Send a message." className="w-full p-0 pl-2 m-0 bg-transparent border-0 resize-none pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent md:pl-0" />
                <MicrophoneIcon title="Toggle to microphone input" className="cursor-pointer absolute w-5 h-5 bottom-2 md:bottom-3.5 right-10 md:right-12" onClick={() => setInputMethod("microphone")}/>
                <button type='submit' disabled={loading || !question} className=" cursor-pointer absolute p-1 rounded-md text-gray-300 bottom-1.5 md:bottom-2.5 hover:bg-gray-100 enabled:dark:hover:text-gray-400 dark:hover:bg-gray-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent right-1 md:right-2 disabled:opacity-40">
                  {loading ? <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg> : <PaperAirplaneIcon className="w-5 h-5" />}
                </button>
              </div>
            </div>
          </form>
          <div className="my-3 text-xs text-center text-gray-600 dark:text-gray-300">
            <span>Conversations may be 💩. Who knows.. 👍</span>
          </div>
        </div>
      )}
    </div>
  )
}
