import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { PauseIcon, PlayIcon, FolderArrowDownIcon, FlagIcon } from '@heroicons/react/24/solid';
import { Switch } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom';
import WaveSurfer from 'wavesurfer.js'
import CursorPlugin from 'wavesurfer.js/src/plugin/cursor';
import TimelinePlugin from 'wavesurfer.js/src/plugin/timeline';
import FlagAudioModal from '../Modals/FlagAudio';
import { Network, Response } from "../Libs/Network";
import Errors from '../Components/Errors';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const trainingAudioDetailLoader = async (request: any) => {
  console.log("Loader:", request);
  return Network.get(`/trainingAudios/${request.params.trainingAudioId}`, true);
}

export default function Details() {
  const navigate = useNavigate();

  const [wavesurfer, setWavesurfer] = useState<WaveSurfer>()
  const [speaker, setSpeaker] = useState<string>()
  const [purpose, setPurpose] = useState<string>()
  const [isPlaying, setPlaying] = useState<boolean>(false)
  const [hasStatic, setStatic] = useState<boolean>(false)
  const [isReviewed, setReviewed] = useState<boolean>(false)
  const [manualTranscription, setManualTranscription] = useState<string>()
  const [showFlagModal, setShowFlagModal] = useState<boolean>(false)
  const [submitErrors, setSubmitErrors] = useState()

  const { data, errors } = useLoaderData() as Response;

  const onSubmit = async (e?: {
    preventDefault: () => void;
  }): Promise<void> => {
    e && e.preventDefault();
    
    // Submit PATCH
    const response = await Network.patch(`/trainingAudios/${data.id}`, true, {
      hasStatic,
      manualTranscription,
    });

    if (response.errors) {
      setSubmitErrors(response.errors);
      return;
    }

    navigate(-1);
    
  };

  useEffect(() => {
    // wavesurfer?.destroy()
    if (data && !errors) {
      setWavesurfer(WaveSurfer.create({
        container: '#waveform',
        waveColor: '#0ea5e9',
        progressColor: '#0369a1',
        cursorColor: '#075985',
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 1,
        height: 120,
        barGap: 3,
        plugins: [
          CursorPlugin.create({
            showTime: true,
            opacity: "1",
            customShowTimeStyle: {
              'background-color': '#000',
              color: '#fff',
              padding: '2px',
              'font-size': '10px'
            }
          }),
          TimelinePlugin.create({
            container: '#wave-timeline'
          })
        ]
      }));
    }
  }, []);

  useEffect(() => {
    if (data && !errors) {
      wavesurfer?.load(`${data.audioUrls.input}`);
      wavesurfer?.on('play', function () {
        setPlaying(true);
      });
      wavesurfer?.on('pause', function () {
        setPlaying(false);
      });
    }
  }, [wavesurfer]);

  if (errors) {
    return <div><Errors errors={errors} /></div>
  }

  return (
    <div className="max-w-4xl mx-auto">
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{data.transmitter || "N/A"} @ {data.icao || "N/A"}</h3>
          <p className="max-w-2xl mt-1 text-sm text-gray-500">Ready for transcription. Fill out the data below!</p>
        </div>
        <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
          <form onSubmit={onSubmit} >
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <div>
                <div id="waveform" />
                <div id="wave-timeline" />
              </div>
              <div className="flex justify-center">
                <div className="w-12 h-12 p-3 m-4 rounded-full cursor-pointer bg-sky-500" onClick={() => wavesurfer?.playPause()} title="Play/Pause Audio">
                  {isPlaying ? <PauseIcon className="mx-auto fill-white" /> : <PlayIcon className="mx-auto fill-white" />}
                </div>
                <a className="w-12 h-12 p-3 m-4 rounded-full cursor-pointer bg-sky-500" href="/parsed.mp3" target={"_blank"} title="Download MP3">
                  <FolderArrowDownIcon className="fill-white" />
                </a>
              </div>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">ICAO</dt>
              <dd className="inline mt-1 text-sm text-gray-900 ">
                  <a href={`https://skyvector.com/airport/${data.icao}`} target="_blank" className="flex items-center underline cursor-pointer">
                    {data.icao || "N/A"} <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-2" />
                  </a>
                </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Source</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.source || "N/A"}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Recorded At</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.eventAt || "N/A"}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Duration</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.duration ? (data.duration / 1000) + "s" : "N/A"}</dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Manual Translation</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <div>Only transcribe what is understandable. For words or segments that are unaudible place 3 dashes like <code>---</code></div>
                <textarea 
                  required 
                  rows={4} 
                  className="w-full mt-2 uppercase rounded-md resize"
                  value={manualTranscription}
                  onChange={(e): void => {
                    setManualTranscription(e.target.value);
                  }} />
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Who is transmitting?</dt>
              <dd className="flex flex-wrap mt-1 text-sm text-gray-900">
                {["Pilot", "Ground", "Tower", "Center", "ATIS", "Other"].map((s) => (
                  <div className={classNames(speaker === s ? "bg-sky-500 text-white" : "bg-sky-50  text-sky-500", "flex items-center justify-center mx-1 my-1 px-2 py-1 text-sm border cursor-pointer font-semibold rounded-3xl w-min border-sky-500 select-none")} onClick={() => setSpeaker(s)}>{s}</div>
                ))}

              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Transmission Purpose</dt>
              <dd className="flex flex-wrap mt-1 text-sm text-gray-900">
                {["Takeoff", "Landing", "Crossing", "Taxi-In", "Taxi-Out", "FLIFO", "ATIS", "Error", "Mayday", "Other"].map((p) => (
                  <div className={classNames(purpose === p ? "bg-sky-500 text-white" : "bg-sky-50  text-sky-500", "flex items-center justify-center px-2 py-1 text-sm border cursor-pointer font-semibold rounded-3xl mx-1 my-1  w-min border-sky-500 select-none whitespace-nowrap")} onClick={() => setPurpose(p)}>{p}</div>
                ))}

              </dd>
            </div>
            <div className="sm:col-span-1">
              <Switch.Group as="div" className="flex items-center justify-between">
                <span className="flex flex-col flex-grow">
                  <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                    Static
                  </Switch.Label>
                  <Switch.Description as="span" className="text-sm text-gray-500">
                    High amount of static? If full static or inaudible, flag instead.
                  </Switch.Description>
                </span>
                <Switch
                  checked={hasStatic}
                  onChange={setStatic}
                  className={classNames(
                    hasStatic ? 'bg-sky-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      hasStatic ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
            <div className="sm:col-span-1">
              <Switch.Group as="div" className="flex items-center justify-between opacity-50">
                <span className="flex flex-col flex-grow">
                  <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                    Reviewed
                  </Switch.Label>
                  <Switch.Description as="span" className="text-sm text-gray-500">
                    Large amount of static in the recording?
                  </Switch.Description>
                </span>
                <Switch
                  disabled
                  checked={isReviewed}
                  onChange={setReviewed}
                  className={classNames(
                    isReviewed ? 'bg-sky-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isReviewed ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
            <Errors errors={submitErrors} />
            <div className="flex space-x-2 sm:col-span-2">
              <button 
                type="submit"
                className="w-5/6 px-4 py-2 font-bold text-white rounded bg-sky-500 hover:bg-sky-700">
                Complete
              </button>
              <button className="flex items-center justify-center w-1/6 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700" onClick={() => setShowFlagModal(true)}>
                <FlagIcon className="w-4 h-4 mr-2 md:mr-4" />
                Flag
              </button>
            </div>
            <FlagAudioModal open={showFlagModal} onCancel={() => setShowFlagModal(false)} />
          </dl>
          </form>
        </div>
      </div>
    </div>
  )
}
