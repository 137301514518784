import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useRouteError,
} from "react-router-dom";

import HomeScreen from "./Screens/HomeScreen";
import TrainingNavigation from "./Navigation/Training";
import ConversationHistory from "./Navigation/ConversationHistory";
import LoginScreen from "./Screens/LoginScreen";
import LogoutScreen from "./Screens/LogoutScreen";
import ConversationScreen from "./Screens/ConversationScreen";
import NBConversationScreen, { NBConversationLoader } from "./Screens/NBConversationScreen";
import TrainingAudiosScreen, { trainingAudiosLoader } from "./Screens/TrainingAudiosScreen";
import TrainingAudioDetailScreen, { trainingAudioDetailLoader } from "./Screens/TrainingAudioDetailScreen";

import NotFoundScreen from './Screens/NotFound';
import { Helmet } from "react-helmet";

function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  return <div>Error</div>;
}

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/logout",
    element: <LogoutScreen />,
  },
  {
    path: "/training",
    element: <TrainingNavigation />,
    children: [
      {
        index: true,
        element: <NotFoundScreen />,
      },
      {
        path: "audio/:source",
        element: <TrainingAudiosScreen />,
        loader: trainingAudiosLoader,
      },
      {
        path: "audio/details/:trainingAudioId",
        element: <TrainingAudioDetailScreen />,
        loader: trainingAudioDetailLoader,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
  {
    path: "/conversation",
    element: <ConversationHistory />,
    children: [
      {
        index: true,
        element: <ConversationScreen />,
      },
      {
        path: ":conversationId",
        element: <TrainingAudioDetailScreen />,
        loader: trainingAudioDetailLoader,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
  {
    path: "/nb/conversations",
    element: (
      <div className="flex flex-col flex-1">
        <Helmet>
          <title>NB Testing testing. Support AMA with a piggy! 🐷</title>
          <meta
            name="description"
            content="Support AMA with a piggy! 🐷"
          />
          <link rel="icon" href="nb-favicon.ico" />
          <link rel="icon" href="nb-favicon.ico" sizes="16x16" />
          <link rel="icon" href="nb-favicon.ico" sizes="32x32" />
        </Helmet>
        <main className="flex-1 ">
          <div className="w-full h-screen bg-gray-700">
            <Outlet />
          </div>
        </main>
      </div>
      
    ),
    children: [
      {
        index: true,
        element: <NBConversationScreen />,
      },
      {
        path: ":conversationId",
        element: <NBConversationScreen />,
        loader: NBConversationLoader,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "*",
    element: <NotFoundScreen />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
