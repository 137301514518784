import { Link, useLoaderData, useParams } from 'react-router-dom';
import Errors from '../Components/Errors';

import { Network, Response } from "../Libs/Network";

interface Source {
  name: string;
  description: string;
}

const types: Record<string, Source> = {
  atis: {
    name: 'ATIS',
    description: "Recordings of ATIS broadcasts ready for transcription."
  },
  atc: {
    name: 'ATC',
    description: "Recordings of UNICOM, Tower, Ground or anything else."
  }
}

export const trainingAudiosLoader = async (request: any) => {
  console.log("Loader:", request.params);
  const response = await Network.get("/trainingAudios", true, { source: request.params.source });

  return response;
}

export default function TrainingAudiosScreen() {
  let { source } = useParams();

  const { data, errors } = useLoaderData() as Response;

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900"><span className="uppercase">{types[source || "atis"].name}</span> Recordings</h1>
          <p className="mt-2 text-sm text-gray-700">
            {types[source || "atis"].description}
          </p>
        </div>
      </div>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    ICAO
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Date/Time
                  </th>
                  <th scope="col" className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data.map((d: any) => (
                  <tr key={d.id} className="hover:bg-gray-50">
                    <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-0">{d.id.slice(-6)}</td>
                    <td className="px-2 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                      {d.icao}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-900 whitespace-nowrap">{d.status}</td>
                    <td className="px-2 py-2 text-sm text-gray-500 whitespace-nowrap">{d.eventAt}</td>
                    <td className="relative py-2 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                      <Link to={`/training/audio/details/${d.id}`} className="p-4 text-sky-600 hover:text-sky-900">
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Errors errors={errors} />
          </div>
        </div>
      </div>
    </div>
  )
}
